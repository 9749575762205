import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

import { FaCheck } from '@react-icons/all-files/fa/FaCheck';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import ContactForm from '../components/ContactForm/ContactForm';

const CostPricing = () => {
  return (
    <Layout>
      <Seo
        title="Pricing | Bridge"
        description="Improve the patient experience with a single platform that offers a robust suite of self-service features for optimal convenience."
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <p className="text-secondary fw-bold h-font-size-lg">
                Bridge Patient Engagement Pricing
              </p>
              <h1>Flexible pricing that adapts to your needs</h1>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="mb-4 justify-content-center">
            <Col lg={8}>
              <div className="c-price-box-wrapper">
                <div className="c-price-box c-price-box__light">
                  <div className="c-price-box-title">Bridge Portal</div>
                  <p>
                    Bridge’s award-winning patient portal technology is the
                    foundation to better patient engagement.
                  </p>
                  <p className="c-price-box-price">
                    <strong>$50</strong> per month
                    <small>Per qualifying provider *</small>
                  </p>
                  <ul className="h-clear-list h-bullet-list">
                    <li>
                      Everything you need to integrate to your EHR, RCM, PM, and
                      existing systems
                    </li>
                    <li>
                      Developed with cutting-edge technology means native mobile
                      responsiveness
                    </li>
                    <li>
                      An all-in-one platform with industry-leading functionality
                    </li>
                  </ul>
                </div>
                <div className="c-price-box c-price-box__secondary">
                  <div className="c-price-box-title">Bridge Portal Add-Ons</div>
                  <p className="text-light-secondary">
                    Design a custom package for your business.
                  </p>
                  <ListGroup>
                    <ListGroup.Item className="bg-secondary text-white">
                      Bridge Mobile
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-secondary text-white">
                      Bridge Scheduling
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-secondary text-white">
                      Bridge Intake
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-secondary text-white">
                      Bridge Pay
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-secondary text-white">
                      Bridge Virtual Care
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-secondary text-white">
                      Bridge Tasking
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col className="text-center">
              <Button
                as={Link}
                variant="primary"
                to="/view-a-demo/"
                className="mb-2 me-0 me-lg-2 mb-lg-0"
              >
                Contact Our Sales Team
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="text-center">
                Improve the patient experience and drive engagement across the
                care journey with add-on modules
              </h2>
              <p className="text-center">
                Offering patients a single access point for all the self-service
                tools they need improves the patient experience, security,
                engagement, and reduces software licensing costs and vendor
                management effort.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col>
              <Table bordered responsive>
                <thead className="bg-secondary text-white">
                  <tr>
                    <th className="py-3">&nbsp;</th>
                    <th className="py-3">Bridge Portal</th>
                    <th className="py-3">Bridge Mobile</th>
                    <th className="py-3">Bridge Scheduling</th>
                    <th className="py-3">Bridge Intake</th>
                    <th className="py-3">Bridge Pay</th>
                    <th className="py-3">Bridge Virtual Care</th>
                    <th className="py-3">Bridge Tasking</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td className="bg-primary">
                      Core product<caption>(Required)</caption>
                    </td>
                    <td colSpan="6" className="bg-light-orange text-center">
                      Optional add-ons
                    </td>
                  </tr>
                  <tr>
                    <td>Client Branded</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Appointment Requests</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Portal Self-Registration</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Two-Factor Authentication</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Patient Forms</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Appointment Reminders</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Broadcast Messaging</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Proxy/Family/Caregiver Accounts</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Healthwise® Patient Education Integration</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Secure Patient Messaging</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Patient Surveys</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Rx Refill Requests</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Lab & Radiology Results</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Medical Records</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Patient Documents</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Google Play Store Published</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Apple App Store Published</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Touch/Face ID Login</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Push Notifications</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Appointment Self-Scheduling</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Appointment Cancellations & Rescheduling</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Appointment Check-In</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Co-Pay and Balance Due Payments</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Clinical Forms</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Consent Forms</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Demographic Forms</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Online Bill Pay</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Payment Plans</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Invoice/Encounter Detail</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Front-Desk Payments</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Zoom® Video Visit Integration</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Live Chat</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Chat Bot</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                    <td className="bg-light-blue">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Task Management</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Custom Task Processes</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="bg-light-blue">
                      <div className="text-center">
                        <FaCheck className="text-secondary" />
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-light fw-bold">
                    <td>Price per provider per month (Approx**)</td>
                    <td>
                      <div className="text-center">
                        <p>$50/mo</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>$45/mo</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>$35/mo</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>$45/mo</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>$20/mo</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>$35/mo</p>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <p>$20/mo</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p>
                <em>
                  *Pricing based on a 100 provider healthcare organization with
                  a 12-month contract. Some types of providers are exempt from
                  this provider count or billed at a reduced rate. Additional
                  pricing structures are available for organizations that do not
                  license technology based on providers.
                </em>
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section variant="contact">
        <Container>
          <Row>
            <Col>
              <h1 className="text-center">REQUEST A DEMO</h1>
            </Col>
          </Row>
          <ContactForm />
        </Container>
      </Section>
    </Layout>
  );
};

export default CostPricing;
